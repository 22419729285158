<template>
    <div class="w-100 pb-5 pt-lg--10">
        <div class="row justify-content-center">
            <div class="col-10">
                <div class="row justify-content-center my-5 content">
                    <div class="col-12">
                        <div class="col-12">
                            <div class="card e_card mb-5">
                                <div class="card-body p-0">
                                    <h1 class="text-grey-900 fw-700 font-lg mb-2 d-block">Welcome to the LMS</h1>
                                    <p class="p-0 mb-4 text-grey-900">Interactive online learning platform for personal
                                        skill enhancement.</p>
                                    <router-link to="/register" class="btn btn-success e_btn">Register</router-link>
                                </div>
                            </div>
                            <div class="row justify-content-center">
                                <div class="col-lg-4 col-md-6 col-sm-12 px-2" v-for="(item, index) in data" :key="index">
                                    <div class="card e_card_feature mb-3">
                                        <div class="card-body p-0">
                                            <img :src=" item.image" height="64px" width="64px" />
                                            <h2 class="card-title fw-600 mt-4 mb-3">{{ item.title }}</h2>
                                            <div class="pt-lg-3">
                                                <p class="card-text">
                                                    <span v-if="!item.showFullDescription">{{ item.text.substring(0, 80)
                                                    }}... </span>
                                                    <span v-else>{{ item.text }}</span>
                                                    <span v-if="item.text.length > 80" class="read-more"
                                                        @click="toggleDescription(item)">
                                                        {{ item.showFullDescription ? 'Read Less' : 'Read More' }}
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<style>
.e_card {
    border-radius: 24px;
    background: #FBAF03;
    padding: 40px 32px;
}

.e_card_feature {
    border-radius: 20px;
    background: #F6F7FC;
    padding: 24px;
}

.e_btn {
    color: #fff;
    background-color: #1E6F5C;
    border-color: #1E6F5C;
    border-radius: 12px;
    padding: 8px 16px;
}

.card-text {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    align-self: stretch;
    font-size: 16px;
    overflow: hidden;
    color: #000;
    text-overflow: ellipsis;
    whitespace: nowrap;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: -0.18px;
}

.content {
    margin-top: 48px !important;
}

.read-more {
    cursor: pointer;
    color: blue;
    font-weight: 500;
    margin-top: 4px;
}

/* Media query for tablet and mobile screens */
@media (max-width: 991.98px) {
    .content {
        margin-top: 96px !important;
    }
}
</style>

<script>
import axios from 'axios'
export default {
    data() {
        return {
            media: process.env.VUE_APP_URL_CLOUD,
            isLoad: true,
            data: []
        }
    },
    created() {
        this.getMilestone()
    },
    methods: {
        async getMilestone() {
            await axios.get(`${process.env.VUE_APP_URL_API}/guest/about-us/program`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.data = res.data.data.map(item => {
                    return { ...item, showFullDescription: false };
                });
                this.isLoad = false
            })
        },
        toggleDescription(item) {
            item.showFullDescription = !item.showFullDescription;
        },
    }
}
</script>
